import React, { useState, useRef } from "react";
// import React from "react";
import Contactimg from "../Images/contact-img.svg";
import Mail from "../Images/mail-img.svg";
import ContactPage from "../Images/contact-page-img.jpg";
import Form from "react-bootstrap/Form";
// import { Button } from "react-bootstrap";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import { Button, Spinner } from "react-bootstrap";
import Swal from "sweetalert2";
import emailjs from "@emailjs/browser";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useEffect } from "react";

// import PhoneInput from "react-phone-number-input";
// import "react-phone-number-input/style.css";

function ContactUs() {
  //   const [isFocused, setIsFocused] = useState({
  //     name: false,
  //     email: false,
  //     message: false,
  //   });
  //   const [isLoading, setIsLoading] = useState(false);

  //   const handleFocus = (field) =>
  //     setIsFocused((prev) => ({ ...prev, [field]: true }));
  //   const handleBlur = (field, event) => {
  //     if (event.target.value.length === 0) {
  //       setIsFocused((prev) => ({ ...prev, [field]: false }));
  //     }
  //   };

  //   const sendmail = () => {
  //     Swal.fire({
  //       position: "center",
  //       icon: "success",
  //       title: "Thank You For Contacting us!",
  //       html: `
  //         <p>We have received your Message and will respond to you as soon as possible.</p>
  //       `,
  //       showConfirmButton: false,
  //       timer: 3000,
  //     });
  //   };

  //   const form = useRef();

  //   const sendEmail = (e) => {
  //     e.preventDefault();
  //     setIsLoading(true);

  //     const formData = new FormData(form.current);
  //     const name = formData.get("name"); // Use the correct field names
  //     const email = formData.get("email"); // Use the correct field names
  //     const message = formData.get("message"); // Use the correct field names

  //     if (!name || !email || !message) {
  //       alert("Please fill in all fields before sending.");
  //       setIsLoading(false);
  //       return;
  //     }

  //     emailjs
  //       .sendForm(
  //         "service_tl60rdc",
  //         "template_z724a7a",
  //         form.current,
  //         "EkSDMto6z7Mg0Wu16"
  //       )
  //       .then(
  //         () => {
  //           console.log("SUCCESS!");
  //           console.log("message sent successfully");
  //           form.current.reset();
  //           sendmail();
  //           setIsLoading(false);
  //         },
  //         (error) => {
  //           console.log("FAILED...", error);
  //           console.log("sending fail");
  //           setIsLoading(false);
  //         }
  //       );
  //   };

  //   return (
  //     <div className="container">
  //       <div className="row mt-5">
  //         <div className="col-md-5">
  //           <h2 className="Feather-font-bold">Schedule a Demo</h2>
  //           <p>
  //             iWantHub improves the apps you build, buy, and sell so you can
  //             deliver better customer and employee experiences. Fill out the form
  //             for a demo.
  //           </p>
  //           <h4 className="Feather-font-bold">Get in Touch</h4>
  //           <div className="row">
  //             <div className="col-md-4 text-center">
  //               <img className="my-3 img-fluid" src={Contact} alt="" />
  //               <p className="Feather-font-bold">Call us directly</p>
  //               <p className="Feather-font-bold text-color">+1 612 8508 005</p>
  //             </div>
  //             <div className="col-md-2"></div>
  //             <div className="col-md-6 text-center">
  //               <img className="my-3" src={Mail} alt="" />
  //               <p className="Feather-font-bold">Drop us a Line</p>
  //               <p className="Feather-font-bold text-color">admin@lathran.com</p>
  //             </div>
  //           </div>
  //           <div className="float">
  //             <img className="img-fluid" src={ContactPage} alt="" />
  //           </div>
  //         </div>
  //         <div className="col-md-2"></div>
  //         <div className="col-md-5">
  //           <div className="countact-form rounded-4">
  //             <form ref={form} onSubmit={sendEmail}>
  //               <div className="my-3">
  //                 <label htmlFor="name" className="OpenSans">
  //                   Full Name
  //                 </label>
  //                 <input
  //                   type="text"
  //                   name="name"
  //                   id="name"
  //                   className={`form-control custom-input ${
  //                     isFocused.name ? "custom-input-focus" : ""
  //                   }`}
  //                   placeholder="e.g., John Doe"
  //                   onFocus={() => handleFocus("name")}
  //                   onBlur={(e) => handleBlur("name", e)}
  //                 />
  //               </div>
  //               <div className="my-3">
  //                 <label htmlFor="email" className="OpenSans">
  //                   Email
  //                 </label>
  //                 <input
  //                   type="email"
  //                   name="email"
  //                   id="email"
  //                   className={`form-control custom-input ${
  //                     isFocused.email ? "custom-input-focus" : ""
  //                   }`}
  //                   placeholder="e.g., john@email.com"
  //                   onFocus={() => handleFocus("email")}
  //                   onBlur={(e) => handleBlur("email", e)}
  //                 />
  //               </div>
  //               <div className="my-3">
  //                 <label htmlFor="message" className="OpenSans">
  //                   Share Your Message
  //                 </label>
  //                 <textarea
  //                   name="message"
  //                   id="message"
  //                   className={`form-control custom-text-area ${
  //                     isFocused.message ? "custom-input-focus" : ""
  //                   }`}
  //                   placeholder="Leave a comment here"
  //                   rows="4"
  //                   onFocus={() => handleFocus("message")}
  //                   onBlur={(e) => handleBlur("message", e)}
  //                   style={{ overflow: "hidden", resize: "none" }}
  //                 ></textarea>
  //               </div>
  //               <Button
  //                 type="submit"
  //                 variant="outline-success"
  //                 className="btn custom-get-started-btn me-2 w-100"
  //                 disabled={isLoading}
  //               >
  //                 {isLoading ? (
  //                   <Spinner animation="border" variant="success" />
  //                 ) : (
  //                   "Send Message"
  //                 )}
  //               </Button>
  //             </form>
  //           </div>
  //         </div>
  //       </div>
  //     </div>
  //   );
  // }

  const [countryCode, setCountryCode] = useState("UK");
  const [phone, setPhone] = useState("");
  const [isFocused1, setIsFocused1] = useState(false);
  const [isFocused2, setIsFocused2] = useState(false);
  const [isFocused3, setIsFocused3] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [value, setValue] = useState("");
  const form = useRef(null);
  const apiUrl = process.env.REACT_APP_BACKEND_URL;

  useEffect(() => {
    fetch("https://api.country.is/")
      .then((res) => res.json())
      .then((data) => setCountryCode(data.country.toLowerCase()))
      .catch((err) => console.error("Error detecting country:", err));
  }, []);
  // String locale = context.getResources().getConfiguration().locale.getCountry();

  const handleFocus1 = () => setIsFocused1(true);
  const handleBlur1 = (event) => {
    if (event.target.value.length === 0) setIsFocused1(false);
  };

  const handleFocus2 = () => setIsFocused2(true);
  const handleBlur2 = (event) => {
    if (event.target.value.length === 0) setIsFocused2(false);
  };

  const handleFocus3 = () => setIsFocused3(true);
  const handleBlur3 = (event) => {
    if (event.target.value.length === 0) setIsFocused3(false);
  };
  // const changing = () => {
  //   const elemet = document.getElementById("change");
  //   elemet.style.color = "black";
  // };

  const sendmail = () => {
    Swal.fire({
      position: "center",
      icon: "success",
      title: "Thank You For Contacting us!",
      html: `<p>We have received your message and will respond to you as soon as possible.</p>`,
      showConfirmButton: false,
      timer: 3000,
    });
  };

  const sendEmail = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    const formData = new FormData(form.current);

    const name = formData.get("to_name");
    const email = formData.get("from_name");
    const message = formData.get("message");
    // const contact = formData.get("contact");
    const contact = value;

    if (!name || !email || !message || !contact) {
      alert("Please fill in all fields before sending.");
      setIsLoading(false);
      return;
    }

    try {
      const response = await fetch(
        `${apiUrl}/api/website/product/getbill`,
        // `${apiUrl}/website/product/getbill`,
        // "http://192.168.10.16:8000/api/website/product/getbill",
        // "https://dubaitechnologynow.com:2005/api/website/product/getbill",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ name, email, message, contact }),
        }
      );

      const result = await response.json();
      if (response.ok) {
        form.current.reset();
        setValue("");
        sendmail();
      } else {
        console.log("FAILED...", result.error);
      }
    } catch (error) {
      console.log("FAILED...", error);
      alert("There was an error sending your message. Please try again later.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="custom-container">
      <div className="row mt-4 ">
        <div className="col-md-6 ">
          <h2 className="Feather-font-bold feature-font-size">
            Schedule a Demo
          </h2>
          <p className="mar-right-three">
            iWantHub improves the apps you build, buy, and sell so you can
            deliver better customer and employee experiences. Fill out the form
            for a demo.
          </p>
          <h4 className="Feather-font-bold">Get in Touch</h4>
          <div className="row">
            <div className="col-md-4 text-center con">
              <img className="my-3 img-fluid " src={Contactimg} alt="" />
              <p className="Feather-font-bold contact">Call us directly</p>
              <a
                href="tel:+16128508005"
                className="Feather-font-bold text-color email"
                style={{ textDecoration: "none", cursor: "pointer" }}
              >
                +1 612 8508 005
              </a>
            </div>
            {/* <div className="col-md-1"></div> */}
            <div className="col-md-6 text-center con">
              <img className="my-3" src={Mail} alt="" />
              <p className="Feather-font-bold contact">Drop us a Line</p>
              <a
                href="mailto:admin@lathran.com"
                className="Feather-font-bold text-color email"
                style={{ textDecoration: "none", cursor: "pointer" }}
              >
                admin@lathran.com
              </a>
            </div>
          </div>
          <div className="row mt-5">
            <img className="img-fluid col-md-9" src={ContactPage} alt="" />
            <div className="col-md-3"></div>
          </div>
        </div>
        {/* <div className="col-md-1"></div> */}
        <div className="col-md-6 ">
          <Form
            ref={form}
            onSubmit={sendEmail}
            className="countact-form rounded-4 mar-left-four"
          >
            <div className="my-3">
              <label htmlFor="to_name" className="OpenSans">
                Full Name
              </label>
              <Form.Control
                pattern="[a-zA-Z ]*"
                type="text"
                id="change"
                name="to_name"
                className="OpenSans input-color mt-2"
                placeholder="eg: John Doe"
                aria-label="Name"
                aria-describedby="basic-addon1"
                style={{ fontWeight: "400" }}
                // onChange={changing}
                // onFocus={handleFocus1}
                // onBlur={handleBlur1}
              />
            </div>
            <div className="my-3">
              <label htmlFor="from_name" className="OpenSans">
                Email
              </label>
              <Form.Control
                name="from_name"
                className="OpenSans input-color mt-2"
                placeholder="eg: john@email.com"
                aria-label="Email"
                aria-describedby="basic-addon1"
                style={{ fontWeight: "400" }}
                type="email"
                // onFocus={handleFocus2}
                // onBlur={handleBlur2}
                // onChange={setValue}
              />
            </div>
            <div className="my-3">
              <label htmlFor="from_name" className="OpenSans">
                Phone Number
              </label>
              {/* <PhoneInput
                name="contact"
                className="OpenSans rounded mt-2 custom-phone"
                international
                defaultCountry="PK"
                placeholder="543210987"
                aria-label="number"
                value={value}
                style={{ fontWeight: "400" }}
                onChange={setValue}
                onFocus={handleFocus2}
                onBlur={handleBlur2}
              /> */}
              <PhoneInput
                className="custom-phone"
                name="contact"
                country={countryCode}
                value={value}
                // onChange={(phone) => setPhone(phone)}
                placeholder="543210987"
                aria-label="number"
                // style={{ fontWeight: "400" }}
                onChange={setValue}
                // onFocus={handleFocus2}
                // onBlur={handleBlur2}
              />
              {/* <Form.Control
                name="contact"
                className="OpenSans input-color mt-2"
                placeholder="543210987"
                aria-label="number"
                aria-describedby="basic-addon1"
                style={{ fontWeight: "400" }}
                // onFocus={handleFocus2}
                // onBlur={handleBlur2}
              /> */}
            </div>
            <div className="my-3">
              <label htmlFor="message" className="OpenSans">
                Share Your Message
              </label>
              <Form.Control
                name="message"
                as="textarea"
                className="OpenSans input-color mt-2"
                placeholder="Message"
                style={{ height: "100px", fontWeight: "400", resize: "none" }}
                // onFocus={handleFocus3}
                // onBlur={handleBlur3}
              />
            </div>
            <Button
              variant="outline-success"
              type="submit"
              className="btn custom-get-started-btn me-2 w-100 mb-3"
              disabled={isLoading}
            >
              {isLoading ? "Sending..." : "Book Now"}
            </Button>
          </Form>
        </div>
      </div>
    </div>
  );
}
export default ContactUs;
