// import logo from "./logo.svg";
import "./App.css";
import "bootstrap/dist/css/bootstrap.css";
import Header from "./Components/Header";
import LandingPage from "./Screens/LandingPage";
import Footer from "./Components/Footer";
import LastFooter from "./Components/LastFooter";
import ContactUs from "./Screens/ContactUs";
import "animate.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

function App() {
  return (
    <div>
      <Header />
      <Routes>
        <Route exact path="/" element={<LandingPage />} />
        <Route exact path="/Contact-us" element={<ContactUs />} />

        {/* <LandingPage /> */}
      </Routes>
      <Footer />
      <LastFooter />
    </div>
  );
}

export default App;
