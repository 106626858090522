import React from "react";
import landingFirstImg from "../Images/first-img.png";
import { Button, Form } from "react-bootstrap";
import mobileImage from "../Images/mobile-image.png";
import characterImage from "../Images/person-image.png";
import webImg from "../Images/web-img.png";
import { useState } from "react";
import { useEffect } from "react";
import iosImg from "../Images/ios-img.png";
import androidImg from "../Images/android-img.png";
import MobilePerson from "../Images/mobile-person.png";
import deleteImg from "../Images/delete.svg";
import DownloadImg from "../Images/downoad.svg";
import CopyLinkImg from "../Images/copy-link.svg";
import Share from "../Images/share.svg";
import ArchieveImg from "../Images/archieve.svg";
import dispIg from "../Images/hover-change-img.png";
import Excel from "../Images/exel-img.png";
import word from "../Images/word-img.png";
import Link from "../Images/link-img.png";
import folder from "../Images/folder-img.png";
import pdf from "../Images/pdf-img.png";
import photo from "../Images/photo-img.png";
import text from "../Images/text-img.png";
import iconFolder from "../Images/icon-folder.png";
import video from "../Images/video-img.png";
import Zip from "../Images/zip-img.png";
import chatIcon from "../Images/chat-logo.png";
import Modal from "react-bootstrap/Modal";
import WatchModal from "../Components/WatchModal";
import animated from "../Images/animated.gif";
import responsiveImg from "../Images/responsive-image.png";
import ScrollAnimation from "react-animate-on-scroll";
import "animate.css/animate.compat.css";
import { NavLink } from "react-router-dom";

function LandingPage() {
  // const words = ["Efficiency", "Productivity", "Innovation"];
  // const [currentWordIndex, setCurrentWordIndex] = useState(0);
  const [modalShow, setModalShow] = React.useState(false);

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     setCurrentWordIndex((prevIndex) => (prevIndex + 1) % words.length);
  //   }, 2000);

  //   return () => clearInterval(interval);
  // }, [words.length]);

  // const [isVisible, setIsVisible] = useState(false);

  // const handleScroll = () => {
  //   const section = document.getElementById("animated-section");
  //   const rect = section.getBoundingClientRect();
  //   setIsVisible(rect.top <= window.innerHeight && rect.bottom >= 0);
  // };

  // useEffect(() => {
  //   window.addEventListener("scroll", handleScroll);
  //   handleScroll();

  //   return () => {
  //     window.removeEventListener("scroll", handleScroll);
  //   };
  // }, []);

  const [isVisible, setIsVisible] = useState(false);

  const handleScroll = () => {
    const section = document.getElementById("animated-section");
    const rect = section.getBoundingClientRect();

    setIsVisible(rect.top <= window.innerHeight && rect.bottom >= 0);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    handleScroll();

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className=" bg-body-tertiary">
      {/* <span class="scrolling-text">
        <span>Executives.</span>
        <span>Startups.</span>
        <span>Developers.</span>
      </span> */}
      <div>
        <div className="custom-container">
          {/* <div className="chat-icon">
            <svg
              width="90"
              height="90"
              viewBox="0 0 90 90"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect width="90" height="90" rx="45" fill="#58CC02" />
              <path
                d="M65.8332 37.7083C65.8332 30.2295 59.7704 24.1667 52.2915 24.1667C47.9522 24.1667 44.0896 26.2131 41.6113 29.39C52.1813 29.7987 60.6248 38.4966 60.6248 49.1667C60.6248 49.3965 60.6209 49.6254 60.6131 49.8533L61.3054 50.0385C63.3186 50.5772 65.1603 48.7354 64.6217 46.7222L64.3563 45.7304C64.142 44.9293 64.2708 44.0829 64.6154 43.3286C65.3974 41.6166 65.8332 39.7134 65.8332 37.7083Z"
                fill="white"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M57.4998 49.1667C57.4998 58.3714 50.0379 65.8333 40.8332 65.8333C38.2592 65.8333 35.8215 65.2498 33.6452 64.2079C32.8971 63.8498 32.0497 63.7241 31.2486 63.9384L28.6943 64.6218C26.6811 65.1605 24.8393 63.3187 25.378 61.3055L26.0614 58.7513C26.2758 57.9501 26.15 57.1027 25.7919 56.3547C24.75 54.1783 24.1665 51.7406 24.1665 49.1667C24.1665 39.9619 31.6284 32.5 40.8332 32.5C50.0379 32.5 57.4998 39.9619 57.4998 49.1667ZM33.5415 51.25C34.6921 51.25 35.6248 50.3173 35.6248 49.1667C35.6248 48.0161 34.6921 47.0833 33.5415 47.0833C32.3909 47.0833 31.4582 48.0161 31.4582 49.1667C31.4582 50.3173 32.3909 51.25 33.5415 51.25ZM40.8332 51.25C41.9838 51.25 42.9165 50.3173 42.9165 49.1667C42.9165 48.0161 41.9838 47.0833 40.8332 47.0833C39.6826 47.0833 38.7498 48.0161 38.7498 49.1667C38.7498 50.3173 39.6826 51.25 40.8332 51.25ZM48.1248 51.25C49.2754 51.25 50.2082 50.3173 50.2082 49.1667C50.2082 48.0161 49.2754 47.0833 48.1248 47.0833C46.9742 47.0833 46.0415 48.0161 46.0415 49.1667C46.0415 50.3173 46.9742 51.25 48.1248 51.25Z"
                fill="white"
              />
            </svg>
          </div> */}
          <div className="row first-sec-padding">
            <div className="col-md-6">
              <h1 className="Feather-font-bold iwant-text ">
                iWantHub is designed for{" "}
                <span className="scrolling-text" style={{ color: "#58CC02" }}>
                  <span>Entrepreneurs.</span>
                  <span>Executives.</span>
                  <span>Startups.</span>
                  <span>SMEs.</span>

                  {/* {words[currentWordIndex]} */}
                </span>
              </h1>
              <p className="OpenSans custo-width text-color  font-normal-size">
                Meet iWantHub to easily organize, share, and collaborate on your
                documents in a secure, intuitive platform.
              </p>
              <div className="d-flex ">
                <a href="https://dxbtechnologyhub.com/login#" target="_blank">
                  <Button
                    variant="outline-success"
                    className="btn custom-get-started-btn me-2"
                  >
                    Get Started
                  </Button>
                </a>
                <Button
                  variant="outline-success"
                  className="btn custom-login-btn "
                  onClick={() => setModalShow(true)}
                >
                  <span>Watch</span>{" "}
                  <span className="ms-2">
                    <svg
                      className="custom-svg-button"
                      width="17"
                      height="17"
                      viewBox="0 0 17 17"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M15.0071 6.12715C16.7754 7.08872 16.7754 9.57794 15.0071 10.5395L4.33051 16.3454C2.61196 17.28 0.5 16.0636 0.5 14.1393L0.500001 2.52741C0.500001 0.603069 2.61196 -0.613314 4.33051 0.32123L15.0071 6.12715Z"
                        fill="#FF9600"
                      />
                    </svg>
                  </span>
                </Button>
                <WatchModal
                // show={modalShow}
                // onHide={() => setModalShow(false)}
                />
              </div>
            </div>
            <div className="col-md-6 position">
              <div className="overlay-img desktop-image">
                <img
                  className="animate__animated animate__fadeIn animate__duration-5s animate__delay-5s mt-5 "
                  src={ArchieveImg}
                  alt=""
                />
                <img
                  className="animate__animated animate__fadeIn animate__duration-1s animate__delay-1s"
                  src={deleteImg}
                  alt=""
                />
                <img
                  className="animate__animated animate__fadeIn animate__duration-3s animate__delay-3s "
                  src={CopyLinkImg}
                  alt=""
                />
                <img
                  className="animate__animated animate__fadeIn animate__duration-2s animate__delay-2s"
                  src={DownloadImg}
                  alt=""
                />
                <img
                  className="animate__animated animate__fadeIn animate__duration-4s animate__delay-4s mar-left"
                  src={Share}
                  alt=""
                />
              </div>

              <img
                className="mobile-show-image custm-img img-fluid  mt-4"
                src={responsiveImg}
                alt=""
              />
              <img
                className="custm-img  desktop-image"
                src={landingFirstImg}
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
      {/* second section */}
      <div className="second-section">
        {/* <div className="container"> */}
        <div className="custom-container " id="feature-section">
          <div className="mar-left-rigt justify-center">
            <p className="text-center Feather-font-bold Features-margin-top">
              Features
            </p>
            <h2 className="Feather-font-bold text-center feature-font-size">
              Features You Love Without Complicated Tech
            </h2>
            <div className="row justify-center gx-5">
              {/* first card */}

              <div className="col-md-4 ">
                <div className="margin-top-three">
                  <div className="logo-border text-center   rounded-4   border-0">
                    <svg
                      width="28"
                      height="30"
                      viewBox="0 0 28 30"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M0.5 12.625C0.5 7.82861 0.5 5.43042 1.06627 4.62361C1.63255 3.81681 3.88749 3.04494 8.39737 1.50119L9.25659 1.20708C11.6075 0.402358 12.7829 0 14 0C15.2171 0 16.3925 0.402359 18.7434 1.20708L19.6026 1.50119C24.1125 3.04494 26.3675 3.81681 26.9337 4.62361C27.5 5.43042 27.5 7.82861 27.5 12.625V14.987C27.5 23.4442 21.1416 27.5482 17.1522 29.2909C16.07 29.7636 15.5289 30 14 30C12.4711 30 11.93 29.7636 10.8478 29.2909C6.85844 27.5482 0.5 23.4442 0.5 14.987V12.625ZM17 10.5C17 12.1569 15.6569 13.5 14 13.5C12.3431 13.5 11 12.1569 11 10.5C11 8.84315 12.3431 7.5 14 7.5C15.6569 7.5 17 8.84315 17 10.5ZM14 22.5C20 22.5 20 21.1569 20 19.5C20 17.8431 17.3137 16.5 14 16.5C10.6863 16.5 8 17.8431 8 19.5C8 21.1569 8 22.5 14 22.5Z"
                        fill="#58CC02"
                      />
                    </svg>
                  </div>
                  <div className="text-center">
                    <h3 className="Feather-font-bold font-size mt-4">
                      Rock-Solid Security
                    </h3>
                    <p className="mt-3 text-color OpenSans font-sixteen custom-left-right-margin">
                      Keep your documents safe and sound with best-in-class
                      protection and easy access, anytime, anywhere.
                    </p>
                  </div>
                </div>
              </div>
              {/* <div className="col-md-1 mt-5"></div> */}

              {/* second card */}

              <div className="col-md-4">
                <div className="margin-top-three">
                  <div className="logo-border   text-center rounded-4   border-0">
                    <svg
                      width="36"
                      height="36"
                      viewBox="0 0 36 36"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M13.5 9C13.5 11.4853 15.5147 13.5 18 13.5C20.4853 13.5 22.5 11.4853 22.5 9C22.5 6.51472 20.4853 4.5 18 4.5C15.5147 4.5 13.5 6.51472 13.5 9Z"
                        fill="#58CC02"
                      />
                      <path
                        d="M3.75 27C3.75 29.4853 5.76472 31.5 8.25 31.5C10.7353 31.5 12.75 29.4853 12.75 27C12.75 24.5147 10.7353 22.5 8.25 22.5C5.76472 22.5 3.75 24.5147 3.75 27Z"
                        fill="#58CC02"
                      />
                      <path
                        d="M27.75 31.5C25.2647 31.5 23.25 29.4853 23.25 27C23.25 24.5147 25.2647 22.5 27.75 22.5C30.2353 22.5 32.25 24.5147 32.25 27C32.25 29.4853 30.2353 31.5 27.75 31.5Z"
                        fill="#58CC02"
                      />
                      <path
                        d="M10.807 11.3435C11.2728 10.9323 11.3172 10.2214 10.906 9.75555C10.4949 9.28972 9.78395 9.24539 9.31812 9.65654C6.59514 12.0599 4.875 15.5798 4.875 19.5C4.875 20.1214 5.37868 20.625 6 20.625C6.62132 20.625 7.125 20.1214 7.125 19.5C7.125 16.2521 8.54742 13.3378 10.807 11.3435Z"
                        fill="#58CC02"
                      />
                      <path
                        d="M26.6819 9.65654C26.216 9.24539 25.5051 9.28972 25.094 9.75555C24.6828 10.2214 24.7272 10.9323 25.193 11.3435C27.4526 13.3378 28.875 16.2521 28.875 19.5C28.875 20.1214 29.3787 20.625 30 20.625C30.6213 20.625 31.125 20.1214 31.125 19.5C31.125 15.5798 29.4049 12.0599 26.6819 9.65654Z"
                        fill="#58CC02"
                      />
                      <path
                        d="M15.2804 30.0325C14.6787 29.8776 14.0654 30.2399 13.9105 30.8416C13.7556 31.4433 14.1179 32.0566 14.7196 32.2115C15.7693 32.4817 16.8687 32.625 18 32.625C19.1313 32.625 20.2307 32.4817 21.2804 32.2115C21.8821 32.0566 22.2444 31.4433 22.0895 30.8416C21.9346 30.2399 21.3213 29.8776 20.7196 30.0325C19.8516 30.2559 18.9405 30.375 18 30.375C17.0595 30.375 16.1484 30.2559 15.2804 30.0325Z"
                        fill="#58CC02"
                      />
                    </svg>
                  </div>
                  <div className="text-center">
                    <h3 className="Feather-font-bold font-size mt-4">
                      Seamless Collaboration
                    </h3>
                    <p className="mt-3 text-color OpenSans font-sixteen custom-left-right-margin">
                      Work together in real-time, share ideas instantly, and
                      keep everyone on the same page—no delays, no hassles.
                    </p>
                  </div>
                </div>
              </div>
              {/* <div className="col-md-1 mt-5"></div> */}

              {/* third card */}

              <div className="col-md-4 ">
                <div className="margin-top-three">
                  <div className="logo-border  text-center rounded-4   border-0">
                    <svg
                      width="36"
                      height="36"
                      viewBox="0 0 36 36"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M24.1543 15.1203C27.0514 16.7353 28.5 17.5428 28.5 18.75C28.5 19.9572 27.0514 20.7647 24.1543 22.3797L23.4048 22.7975C22.5815 23.2565 22.1699 23.4859 21.8983 23.8437C21.6267 24.2015 21.5259 24.6471 21.3242 25.5382L21.1407 26.3496C20.4311 29.4857 20.0763 31.0537 18.8766 31.4268C17.677 31.7998 16.4269 30.7309 13.9268 28.5929L13.28 28.0398C12.5696 27.4322 12.2144 27.1284 11.7749 26.9918C11.3355 26.8551 10.8615 26.901 9.91362 26.9929L9.05065 27.0765C5.71499 27.3998 4.04715 27.5614 3.30572 26.5847C2.56429 25.608 3.24028 24.1398 4.59226 21.2035L4.94203 20.4439C5.32622 19.6094 5.51832 19.1922 5.51832 18.75C5.51832 18.3078 5.32622 17.8906 4.94203 17.0561L4.59226 16.2965C3.24028 13.3602 2.56429 11.892 3.30572 10.9153C4.04716 9.93863 5.71498 10.1002 9.05064 10.4235L9.91362 10.5071C10.8615 10.599 11.3355 10.6449 11.7749 10.5082C12.2144 10.3716 12.5696 10.0678 13.28 9.46025L13.9268 8.90712C16.4269 6.76914 17.677 5.70015 18.8766 6.07321C20.0763 6.44627 20.4311 8.01432 21.1407 11.1504L21.3242 11.9618C21.5259 12.8529 21.6267 13.2985 21.8983 13.6563C22.1699 14.0141 22.5815 14.2435 23.4048 14.7025L24.1543 15.1203Z"
                        fill="#58CC02"
                      />
                      <path
                        d="M32.2496 7.30098C32.4083 7.34596 32.4092 7.62761 32.2509 7.67367C31.5277 7.88397 30.4588 8.27393 29.8731 8.86362C29.2873 9.45331 28.9046 10.5248 28.6992 11.2494C28.6542 11.4081 28.3726 11.409 28.3265 11.2507C28.1162 10.5275 27.7263 9.45858 27.1366 8.87286C26.5469 8.28714 25.4753 7.90442 24.7508 7.699C24.5921 7.65402 24.5911 7.37237 24.7495 7.32631C25.4727 7.11601 26.5416 6.72605 27.1273 6.13636C27.713 5.54667 28.0958 4.47513 28.3012 3.75056C28.3462 3.59189 28.6278 3.59094 28.6739 3.7493C28.8842 4.47247 29.2741 5.5414 29.8638 6.12712C30.4535 6.71283 31.525 7.09556 32.2496 7.30098Z"
                        fill="#58CC02"
                      />
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M31.125 28.5C31.125 29.1213 30.6213 29.625 30 29.625L29.625 29.625L29.625 30C29.625 30.6213 29.1213 31.125 28.5 31.125C27.8787 31.125 27.375 30.6213 27.375 30L27.375 29.625L27 29.625C26.3787 29.625 25.875 29.1213 25.875 28.5C25.875 27.8787 26.3787 27.375 27 27.375L27.375 27.375L27.375 27C27.375 26.3787 27.8787 25.875 28.5 25.875C29.1213 25.875 29.625 26.3787 29.625 27L29.625 27.375L30 27.375C30.6213 27.375 31.125 27.8787 31.125 28.5Z"
                        fill="#58CC02"
                      />
                    </svg>
                  </div>
                  <div className="text-center">
                    <h3 className="Feather-font-bold font-size mt-4">
                      Favorites at Your Fingertips
                    </h3>
                    <p className="mt-3 custom-left-right-padding text-color OpenSans font-sixteen custom-left-right-margin">
                      Need quick access? Mark your most-used files as favorites
                      and have them ready whenever you are.
                    </p>
                  </div>
                </div>
              </div>

              {/* <div className="col-md-1 mt-5"></div> */}

              {/* fourth card */}
              {/* </div> */}
              {/* <div className="row justify-center g-5"> */}
              <div className="col-md-4 ">
                <div className="image-change text-center margin-top-three rounded-4 border-0">
                  <svg
                    className="default-image"
                    width="36"
                    height="36"
                    viewBox="0 0 36 36"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M26.7292 29.5509C26.5952 27.9096 27.9536 26.5394 29.5807 26.6745C29.8628 26.6979 30.2006 26.8006 30.5446 26.9051C30.5777 26.9152 30.6109 26.9253 30.6441 26.9353C30.6741 26.9444 30.7041 26.9534 30.7341 26.9624C31.0502 27.0575 31.3646 27.152 31.6038 27.2812C32.9767 28.0227 33.4198 29.7947 32.56 31.1044C32.4102 31.3325 32.1779 31.5662 31.9443 31.8011C31.9221 31.8234 31.8999 31.8457 31.8778 31.868C31.8557 31.8904 31.8336 31.9127 31.8115 31.9351C31.5786 32.1707 31.347 32.4051 31.1208 32.5561C29.8224 33.4235 28.0657 32.9765 27.3306 31.5916C27.2025 31.3504 27.1088 31.0332 27.0146 30.7144C27.0057 30.6841 26.9967 30.6538 26.9877 30.6236C26.9778 30.5901 26.9678 30.5566 26.9578 30.5232C26.8542 30.1762 26.7524 29.8355 26.7292 29.5509Z"
                      fill="#58CC02"
                    />
                    <path
                      d="M30.1917 16.7144C30.1917 24.2886 24.1046 30.4287 16.5958 30.4287C9.08706 30.4287 3 24.2886 3 16.7144C3 9.14012 9.08706 3 16.5958 3C24.1046 3 30.1917 9.14012 30.1917 16.7144Z"
                      fill="#58CC02"
                    />
                    <path
                      d="M16.5 13L14 17H19L16.5 21"
                      stroke="#EEFAE6"
                      stroke-width="2.25"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                  <img className="hover-image" src={dispIg} alt="Hover Image" />
                </div>

                {/* <div className="image-change text-center margin-top-three rounded-4   border-0">
                  <svg
                    width="36"
                    height="36"
                    viewBox="0 0 36 36"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M26.7292 29.5509C26.5952 27.9096 27.9536 26.5394 29.5807 26.6745C29.8628 26.6979 30.2006 26.8006 30.5446 26.9051C30.5777 26.9152 30.6109 26.9253 30.6441 26.9353C30.6741 26.9444 30.7041 26.9534 30.7341 26.9624C31.0502 27.0575 31.3646 27.152 31.6038 27.2812C32.9767 28.0227 33.4198 29.7947 32.56 31.1044C32.4102 31.3325 32.1779 31.5662 31.9443 31.8011C31.9221 31.8234 31.8999 31.8457 31.8778 31.868C31.8557 31.8904 31.8336 31.9127 31.8115 31.9351C31.5786 32.1707 31.347 32.4051 31.1208 32.5561C29.8224 33.4235 28.0657 32.9765 27.3306 31.5916C27.2025 31.3504 27.1088 31.0332 27.0146 30.7144C27.0057 30.6841 26.9967 30.6538 26.9877 30.6236C26.9778 30.5901 26.9678 30.5566 26.9578 30.5232C26.8542 30.1762 26.7524 29.8355 26.7292 29.5509Z"
                      fill="#58CC02"
                    />
                    <path
                      d="M30.1917 16.7144C30.1917 24.2886 24.1046 30.4287 16.5958 30.4287C9.08706 30.4287 3 24.2886 3 16.7144C3 9.14012 9.08706 3 16.5958 3C24.1046 3 30.1917 9.14012 30.1917 16.7144Z"
                      fill="#58CC02"
                    />
                    <path
                      d="M16.5 13L14 17H19L16.5 21"
                      stroke="#EEFAE6"
                      stroke-width="2.25"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </div>
                <img className="dis-show" src={dispIg} alt="" /> */}
                {/* <svg
                  className="dis-show"
                  width="72"
                  height="72"
                  viewBox="0 0 72 72"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect width="72" height="72" rx="20" fill="#58CC02" />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M44.7292 47.5509C44.5952 45.9096 45.9536 44.5394 47.5807 44.6745C47.8628 44.6979 48.2006 44.8006 48.5446 44.9051C48.5777 44.9152 48.6109 44.9253 48.6441 44.9353C48.6741 44.9444 48.7041 44.9534 48.7341 44.9624C49.0502 45.0575 49.3646 45.152 49.6038 45.2812C50.9767 46.0227 51.4198 47.7947 50.56 49.1044C50.4102 49.3325 50.1779 49.5662 49.9443 49.8011C49.9221 49.8234 49.8999 49.8457 49.8778 49.868C49.8557 49.8904 49.8336 49.9127 49.8115 49.9351C49.5786 50.1707 49.347 50.4051 49.1208 50.5561C47.8224 51.4235 46.0657 50.9765 45.3306 49.5916C45.2025 49.3504 45.1088 49.0332 45.0146 48.7144C45.0057 48.6841 44.9967 48.6538 44.9877 48.6236C44.9778 48.5901 44.9678 48.5566 44.9578 48.5232C44.8542 48.1762 44.7524 47.8355 44.7292 47.5509Z"
                    fill="white"
                  />
                  <path
                    d="M48.1917 34.7144C48.1917 42.2886 42.1046 48.4287 34.5958 48.4287C27.0871 48.4287 21 42.2886 21 34.7144C21 27.1401 27.0871 21 34.5958 21C42.1046 21 48.1917 27.1401 48.1917 34.7144Z"
                    fill="white"
                  />
                  <path
                    d="M34.5 31L32 35H37L34.5 39"
                    stroke="#58CC02"
                    stroke-width="2.25"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg> */}
                <div className="text-center">
                  <h3 className="Feather-font-bold font-size mt-4">
                    Lightning-Fast Search
                  </h3>
                  <p className="mt-3 OpenSans text-color font-sixteen custom-left-right-margin ">
                    Find exactly what you're looking for in seconds with
                    advanced search that digs deep into your files.
                  </p>
                </div>
              </div>
              {/* <div className="col-md-4 mt-5"></div> */}

              {/* fifth card  */}

              <div className="col-md-4 ">
                <div className="logo-border margin-top-three text-center rounded-4   border-0">
                  <svg
                    width="36"
                    height="36"
                    viewBox="0 0 36 36"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12.7648 3.00001H23.235C23.5837 2.99992 23.8511 2.99986 24.0848 3.02272C25.7465 3.18527 27.1066 4.18437 27.6834 5.53016H8.31642C8.89319 4.18437 10.2533 3.18527 11.915 3.02272C12.1488 2.99986 12.4161 2.99992 12.7648 3.00001Z"
                      fill="#58CC02"
                    />
                    <path
                      d="M9.46578 7.08468C7.37984 7.08468 5.66944 8.3443 5.09865 10.0154C5.08675 10.0502 5.07535 10.0852 5.06444 10.1204C5.66166 9.93954 6.28321 9.82139 6.91241 9.74073C8.53297 9.53297 10.581 9.53308 12.96 9.5332L13.1377 9.53321L23.2982 9.5332C25.6772 9.53308 27.7252 9.53297 29.3458 9.74073C29.975 9.82139 30.5965 9.93954 31.1938 10.1204C31.1829 10.0852 31.1714 10.0502 31.1595 10.0154C30.5888 8.3443 28.8784 7.08468 26.7924 7.08468H9.46578Z"
                      fill="#58CC02"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M13.0086 11.3131H22.9914C28.0536 11.3131 30.5848 11.3131 32.0065 12.7933C33.4283 14.2736 33.0938 16.5605 32.4247 21.1343L31.7903 25.4717C31.2656 29.0586 31.0033 30.852 29.6575 31.926C28.3117 33 26.3268 33 22.357 33H13.643C9.67321 33 7.68829 33 6.34251 31.926C4.99672 30.852 4.73439 29.0586 4.20972 25.4717L3.57527 21.1343C2.90622 16.5605 2.5717 14.2736 3.99348 12.7933C5.41525 11.3131 7.94636 11.3131 13.0086 11.3131ZM12 27.0002C12 26.3789 12.5596 25.8752 13.25 25.8752H22.75C23.4404 25.8752 24 26.3789 24 27.0002C24 27.6215 23.4404 28.1252 22.75 28.1252H13.25C12.5596 28.1252 12 27.6215 12 27.0002Z"
                      fill="#58CC02"
                    />
                  </svg>
                </div>
                <div className="text-center">
                  <h3 className="Feather-font-bold font-size mt-4">
                    Simple File Management
                  </h3>
                  <p className="mt-3 OpenSans text-color font-sixteen custom-left-right-margin">
                    Organize your documents with ease—copy, move, or rename
                    files in a flash and keep your workspace tidy.
                  </p>
                </div>
              </div>
              {/* <div className="col-md-1 mt-5"></div> */}

              {/* sixth card  */}

              <div className="col-md-4 ">
                <div className="logo-border margin-top-three text-center rounded-4   border-0">
                  <svg
                    width="36"
                    height="36"
                    viewBox="0 0 36 36"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M3 9.31579C3 6.3385 3 4.84985 3.98851 3.92493C4.97703 3 6.56802 3 9.75 3C12.932 3 14.523 3 15.5115 3.92493C16.5 4.84985 16.5 6.3385 16.5 9.31579V26.6842C16.5 29.6615 16.5 31.1501 15.5115 32.0751C14.523 33 12.932 33 9.75 33C6.56802 33 4.97703 33 3.98851 32.0751C3 31.1501 3 29.6615 3 26.6842V9.31579Z"
                      fill="#58CC02"
                    />
                    <path
                      d="M19.5 23.1C19.5 19.9887 19.5 18.4331 20.4885 17.4665C21.477 16.5 23.068 16.5 26.25 16.5C29.432 16.5 31.023 16.5 32.0115 17.4665C33 18.4331 33 19.9887 33 23.1V26.4C33 29.5113 33 31.0669 32.0115 32.0335C31.023 33 29.432 33 26.25 33C23.068 33 21.477 33 20.4885 32.0335C19.5 31.0669 19.5 29.5113 19.5 26.4V23.1Z"
                      fill="#58CC02"
                    />
                    <path
                      d="M19.5 8.25C19.5 6.6192 19.5 5.80381 19.7569 5.16061C20.0994 4.30301 20.7565 3.62165 21.5834 3.26642C22.2037 3 22.9899 3 24.5625 3H27.9375C29.5101 3 30.2963 3 30.9166 3.26642C31.7435 3.62165 32.4006 4.30301 32.7431 5.16061C33 5.80381 33 6.6192 33 8.25C33 9.8808 33 10.6962 32.7431 11.3394C32.4006 12.197 31.7435 12.8784 30.9166 13.2336C30.2963 13.5 29.5101 13.5 27.9375 13.5H24.5625C22.9899 13.5 22.2037 13.5 21.5834 13.2336C20.7565 12.8784 20.0994 12.197 19.7569 11.3394C19.5 10.6962 19.5 9.8808 19.5 8.25Z"
                      fill="#58CC02"
                    />
                  </svg>
                </div>
                <div className="text-center">
                  <h3 className="Feather-font-bold font-size mt-4">
                    View Your Way
                  </h3>
                  <p className="mt-3 OpenSans text-color custom-left-right-padding font-sixteen">
                    Switch between grid and list views, with handy file previews
                    in grid mode, so you can browse your documents just how you
                    like.
                  </p>
                </div>
              </div>
            </div>
            {/* <div className="justify-center mt-2"> */}
            <a href="https://dxbtechnologyhub.com/login#" target="_blank">
              <Button
                variant="outline-success"
                className="btn custom-login-btn mar"
              >
                Get Started Today
              </Button>
            </a>
            {/* </div> */}
          </div>
          {/* no more section  */}

          <div className="no-more-border">
            <div className="row">
              <div className="col-md-7">
                <h1 className="Feather-font-bold no-more-h1">
                  No more playing hide-and-seek with your files.
                </h1>
                <p className="OpenSans no-more-p  mt-4">
                  iWantHub global search helps you spot any file instantly, even
                  if they aren't intuitively named.
                </p>
                <a href="https://dxbtechnologyhub.com/login#" target="_blank">
                  <Button
                    variant="outline-success"
                    className="btn custom-started-free-btn "
                  >
                    Get Started for free
                  </Button>
                </a>
                <img
                  className="zindex-character img-fluid    "
                  src={characterImage}
                  alt=""
                />
              </div>
              <div className="col-md-5">
                <img className="zindex " src={animated} alt="" />
                {/* <img className="zindex img-fluid" src={mobileImage} alt="" /> */}
              </div>
            </div>
          </div>
          {/* add file */}

          <div
            id="animated-section"
            className="folder-container desktop-image"
            style={{ marginTop: "15rem", marginBottom: "25rem" }}
          >
            <h3 className="add-any-animation Feather-font-bold add-mar">
              Add any file with ease - iWantHub has you covered.
            </h3>
            <div className="img-folder">
              <div
                className={`banner-image-wrap ${
                  isVisible ? "show" : "reverse"
                }`}
              >
                <div className="img-word text-center">
                  <img src={word} alt="word" />
                  <p className="excel-text">
                    System_Design_Specifications.docx
                  </p>
                </div>
                <div className="img-pdf text-center">
                  <img src={pdf} alt="pdf" />
                  <p className="excel-text">API_Integration_Guide.pdf</p>
                </div>
                <div className="img-excel text-center">
                  <img src={Excel} alt="excel" />
                  <p className="excel-text">Data_Migration_Protocols.xlsx</p>
                </div>
                <div className="img-zip text-center">
                  <img src={Zip} alt="zip" />
                  <p className="excel-text">API_Resource_Pack.zip</p>
                </div>
                <div className="img-text text-center">
                  <img src={text} alt="text" />
                  <p className="excel-text">Server_Config_Scripts.txt</p>
                </div>
                <div className="img-video text-center">
                  <img src={video} alt="video" />
                  <p className="excel-text">User_Training_Session.mp4</p>
                </div>
                <div className="img-photo text-center">
                  <img src={photo} alt="photo" />
                  <p className="excel-text">
                    Network_Architecture_Diagram.jpeg
                  </p>
                </div>
                <div className="img-link text-center">
                  {/* <span className="back">asdasdad</span> */}
                  <img src={Link} alt="link" />
                  <p className="excel-text">DevOps_Dashboard_Link.url</p>
                </div>
                <div className="img-second-text text-center">
                  <img src={iconFolder} alt="" />
                  <p className="excel-text">Sub_Folder</p>
                </div>
              </div>
            </div>

            <img className="img-fluid folder-img" src={folder} alt="Folder" />
          </div>

          {/* mobile responsive  */}

          <div
            id="animated-section"
            className="folder-container mobile-show-image"
            style={{ marginTop: "5rem", marginBottom: "5rem" }}
          >
            <h3 className="text-center Feather-font-bold ">
              Add any file with ease - iWantHub has you covered.
            </h3>
            <img
              className="img-fluid mobile-folder-img"
              src={folder}
              alt="Folder"
            />
          </div>

          {/* <div
            id="animated-section"
            className="folder-container"
            style={{ marginTop: "15rem", marginBottom: "25rem" }}
          >
            <h3 className="add-any-animation  Feather-font-bold add-mar">
              Add any file with ease - iWantHub has you covered.
            </h3>
            <div className="img-folder">
              <div className={`banner-image-wrap ${isVisible ? "show" : ""}`}>
                <div className="img-word text-center">
                  <img src={word} alt="" />
                  <p className=" excel-text">
                    System_Design_Specifications.docx
                  </p>
                </div>
                <div className="img-pdf text-center">
                  <img src={pdf} alt="" />
                  <p className="excel-text">API_Integration_Guide.pdf</p>
                </div>
                <div className="img-excel text-center">
                  <img className="img-fluid" src={Excel} alt="" />
                  <p className="excel-text">Data_Migration_Protocols.xlsx</p>
                </div>
                <div className="img-zip text-center">
                  <img src={Zip} alt="" />
                  <p className="excel-text">API_Resource_Pack.zip</p>
                </div>
                <div className="img-text text-center">
                  <img src={text} alt="" />
                  <p className="excel-text">Server_Config_Scripts</p>
                </div>
                <div className="img-video text-center">
                  <img src={video} alt="" />
                  <p className="excel-text">User_Training_Session.mp4</p>
                </div>
                <div className="img-photo text-center">
                  <img src={photo} alt="" />
                  <p className="excel-text text-center">
                    Network_Architecture_Diagram.jpeg
                  </p>
                </div>
                <div className="img-link text-center">
                  <img src={Link} alt="" />
                  <p className="excel-text">DevOps_Dashboard_Link.url</p>
                </div>
                <div className="img-second-text text-center">
                  <img src={text} alt="" />
                  <p className="excel-text">Server_Config_Scripts.txt</p>
                </div>
              </div>
            </div>
            <img className="img-fluid folder-img" src={folder} alt="Folder" />
          </div> */}

          {/* <div className="add-file">
            <div className="row mx-auto">
              <ScrollAnimation animateIn="fadeInUp" duration={2}>
                <div className="col-md-4 text-center file-item">
                  <div className="file-details">
                    <img src={word} alt="Word File" />
                    <p className="file-name">
                      System_Design_Specifications.docx
                    </p>
                  </div>
                </div>
              </ScrollAnimation>
              <div className="col-md-5 text-center">
                <h3 className="add-info">
                  Add any file with ease - iWantHub has you covered.
                </h3>
              </div>
              <ScrollAnimation animateIn="fadeInUp" duration={2}>
                <div className="col-md-3 text-center file-item">
                  <div className="file-details">
                    <img src={pdf} alt="PDF File" />
                    <p className="file-name">API_Integration_Guide.pdf</p>
                  </div>
                </div>
              </ScrollAnimation>
            </div>

            <div className="row mx-auto">
              <ScrollAnimation animateIn="fadeInUp" duration={2}>
                <div className="col-md-3 text-center file-item">
                  <div className="file-details">
                    <img src={Excel} alt="Excel File" />
                    <p className="file-name">Data_Migration_Protocols.xlsx</p>
                  </div>
                </div>
              </ScrollAnimation>
              <ScrollAnimation animateIn="fadeInUp" duration={2}>
                <div className="col-md-3 text-center file-item">
                  <div className="file-details">
                    <img src={Zip} alt="ZIP File" />
                    <p className="file-name">API_Resource_Pack.zip</p>
                  </div>
                </div>
              </ScrollAnimation>
              <ScrollAnimation animateIn="fadeInUp" duration={2}>
                <div className="col-md-3 text-center file-item">
                  <div className="file-details">
                    <img src={text} alt="Text File" />
                    <p className="file-name">Server_Config_Scripts.txt</p>
                  </div>
                </div>
              </ScrollAnimation>
              <ScrollAnimation animateIn="fadeInUp" duration={2}>
                <div className="col-md-3 text-center file-item">
                  <div className="file-details">
                    <img src={text} alt="Text File" />
                    <p className="file-name">Server_Config_Scripts</p>
                  </div>
                </div>
              </ScrollAnimation>
            </div>

            <div className="row mx-auto">
              <ScrollAnimation animateIn="fadeInUp" duration={2}>
                <div className="col-md-3 text-center file-item">
                  <div className="file-details">
                    <img src={video} alt="Video File" />
                    <p className="file-name">User_Training_Session.mp4</p>
                  </div>
                </div>
              </ScrollAnimation>
              <ScrollAnimation animateIn="fadeInUp" duration={2}>
                <div className="col-md-3 text-center file-item">
                  <div className="file-details">
                    <img src={photo} alt="Photo File" />
                    <p className="file-name">
                      Network_Architecture_Diagram.jpeg
                    </p>
                  </div>
                </div>
              </ScrollAnimation>
              <ScrollAnimation animateIn="fadeInUp" duration={2}>
                <div className="col-md-3 text-center file-item">
                  <div className="file-details">
                    <img src={Link} alt="Link File" />
                    <p className="file-name">DevOps_Dashboard_Link.url</p>
                  </div>
                </div>
              </ScrollAnimation>
              <ScrollAnimation animateIn="fadeInUp" duration={2}>
                <div className="col-md-3 text-center file-item">
                  <div className="file-details">
                    <img src={pdf} alt="PDF File" />
                    <p className="file-name">API_Integration_Guide.pdf</p>
                  </div>
                </div>
              </ScrollAnimation>
            </div>

            <div className="text-center folder">
              <img src={folder} alt="Folder" />
            </div>
          </div> */}

          {/* <div className="add-file">
            <div className="row mx-auto">
              <div className="col-md-4 text-center file-item">
                <div className="file-details">
                  <img src={word} alt="Word File" />
                  <p className="file-name">System_Design_Specifications.docx</p>
                </div>
              </div>
              <div className="col-md-5 text-center">
                <h3 className="add-info">
                  Add any file with ease - iWantHub has you covered.
                </h3>
              </div>
              <div className="col-md-3 text-center file-item">
                <div className="file-details">
                  <img src={pdf} alt="PDF File" />
                  <p className="file-name">API_Integration_Guide.pdf</p>
                </div>
              </div>
            </div>

            <div className="row mx-auto">
              <div className="col-md-3 text-center file-item">
                <div className="file-details">
                  <img src={Excel} alt="Excel File" />
                  <p className="file-name">Data_Migration_Protocols.xlsx</p>
                </div>
              </div>
              <div className="col-md-3 text-center file-item">
                <div className="file-details">
                  <img src={Zip} alt="ZIP File" />
                  <p className="file-name">API_Resource_Pack.zip</p>
                </div>
              </div>
              <div className="col-md-3 text-center file-item">
                <div className="file-details">
                  <img src={text} alt="Text File" />
                  <p className="file-name">Server_Config_Scripts.txt</p>
                </div>
              </div>
              <div className="col-md-3 text-center file-item">
                <div className="file-details">
                  <img src={text} alt="Text File" />
                  <p className="file-name">Server_Config_Scripts</p>
                </div>
              </div>
            </div>

            <div className="row mx-auto">
              <div className="col-md-3 text-center file-item">
                <div className="file-details">
                  <img src={video} alt="Video File" />
                  <p className="file-name">User_Training_Session.mp4</p>
                </div>
              </div>
              <div className="col-md-3 text-center file-item">
                <div className="file-details">
                  <img src={photo} alt="Photo File" />
                  <p className="file-name">Network_Architecture_Diagram.jpeg</p>
                </div>
              </div>
              <div className="col-md-3 text-center file-item">
                <div className="file-details">
                  <img src={Link} alt="Link File" />
                  <p className="file-name">DevOps_Dashboard_Link.url</p>
                </div>
              </div>
              <div className="col-md-3 text-center file-item">
                <div className="file-details">
                  <img src={pdf} alt="PDF File" />
                  <p className="file-name">API_Integration_Guide.pdf</p>
                </div>
              </div>
            </div>

            <div className="text-center folder">
              <img src={folder} alt="Folder" />
            </div>
          </div> */}

          {/* real add file */}

          {/* <div className="add-file">
            <div className="row mx-auto">
              <div className="col-md-4 text-center desktop-image">
                <div className="word-mar-right">
                  <img src={word} alt="" />
                  <p className=" excel-text">
                    System_Design_Specifications.docx
                  </p>
                </div>
              </div>
              <div className="col-md-5 text-center">
                <h3 className="add-any  Feather-font-bold add-mar">
                  Add any file with ease - iWantHub has you covered.
                </h3>
              </div>
              <div className="col-md-2 text-center desktop-image">
                <div className="mar-pdf desktop-image">
                  <img src={pdf} alt="" />
                  <p className="excel-text">API_Integration_Guide.pdf</p>
                </div>
              </div>
            </div>
            <div className="row  mx-auto desktop-image">
              <div className="col-md-2 text-center desktop-image">
                <div className="main-excel">
                  <img className="img-fluid" src={Excel} alt="" />
                  <p className="excel-text">Data_Migration_Protocols.xlsx</p>
                </div>
              </div>
              <div className="col-md-2 desktop-image"></div>
              <div className="col-md-4 text-center desktop-image">
                <div className="main-zip desktop-image ">
                  <img src={Zip} alt="" />
                  <p className="excel-text">API_Resource_Pack.zip</p>
                </div>
              </div>
              <div className="col-md-2 desktop-image"></div>
              <div className="col-md-2 text-center main-excel desktop-image">
                <div className="main-excel mar-pdf">
                  <img src={text} alt="" />
                  <p className="excel-text">Server_Config_Scripts</p>
                </div>
              </div>
            </div>
            <div className="row mx-auto desktop-image">
              <div className="col-md-2 desktop-image"></div>
              <div className="col-md-2 text-center desktop-image">
                <div className="main-excel desktop-image">
                  <img className="img-fluid" src={video} alt="" />
                  <p className="excel-text">User_Training_Session.mp4</p>
                </div>
              </div>
              <div className="col-md-2 desktop-image"></div>
              <div className="col-md-2 desktop-image"></div>
              <div className="col-md-2  text-center desktop-image">
                <div className="main-excel desktop-image">
                  <img src={photo} alt="" />
                  <p className="excel-text">
                    Network_Architecture_Diagram.jpeg
                  </p>
                </div>
              </div>
            </div>
            <div className="row mx-auto desktop-image">
              <div className="col-md-2 text-center desktop-image">
                <div className="main-excel desktop-image">
                  <img src={Link} alt="" />
                  <p className="excel-text">DevOps_Dashboard_Link.url</p>
                </div>
              </div>
              <div className="col-md-2 desktop-image"></div>
              <div className="col-md-2 desktop-image"></div>
              <div className="col-md-2 desktop-image"></div>
              <div className="col-md-2 desktop-image"></div>
              <div className="col-md-2 text-center desktop-image">
                <div className="main-excel desktop-image">
                  <img src={text} alt="" />
                  <p className="excel-text">Server_Config_Scripts.txt</p>
                </div>
              </div>
            </div>
            <div className="text-center folder">
              <img src={folder} alt="" />
            </div>
          </div> */}
        </div>

        {/* </div> */}

        {/* dowload section */}

        <div className="bg-body-tertiary download-sec">
          <div className="custom-container">
            <p className="text-center download-text resp-mar1 Feather-font-bold">
              Download the app
            </p>
            <h3 className="Feather-font-bold text-center">
              One Platform, Infinite Possibilities
            </h3>
            <div className="row mt-5">
              <div className="col-md-4 ">
                <div className="second-sec-card rounded-5">
                  <div className="text-center">
                    <img className="img-fluid img-shadow" src={webImg} alt="" />
                  </div>
                  <div>
                    <h5 className="Feather-font-bold web-text">For WebApp</h5>
                    <p className="OpenSans web-text-p text-color">
                      Automatically back up your computer’s folders and files
                      with iWantHub.
                    </p>
                    <p className="learn-p Feather-font-bold">Learn more</p>
                  </div>
                </div>
              </div>
              <div className="col-md-4 responsive-margin-top">
                <div className="second-sec-card rounded-5">
                  <div className="text-center">
                    <img className="img-fluid img-shadow" src={iosImg} alt="" />
                  </div>
                  <div>
                    <h5 className="Feather-font-bold web-text">For iOS</h5>
                    <p className="OpenSans web-text-p text-color">
                      Automatically save your phone’s photos and videos with
                      iWantHub for iOS.
                    </p>
                    <p className="learn-p Feather-font-bold">Learn more</p>
                  </div>
                </div>
              </div>
              <div className="col-md-4 ">
                <div className="second-sec-card rounded-5">
                  <div className="text-center">
                    <img
                      className="img-fluid img-shadow"
                      src={androidImg}
                      alt=""
                    />
                  </div>
                  <div>
                    <h5 className="Feather-font-bold web-text">For Android™</h5>
                    <p className="OpenSans web-text-p text-color">
                      Automatically save your phone’s photos and videos with
                      iWantHub for Android™.
                    </p>
                    <p className="learn-p Feather-font-bold">Learn more</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Ready section */}

        <div className="custom-container ready-margin ">
          <div className="row bottom-margin">
            <div className="col-md-6 ">
              <h2 className="ready-to  Feather-font-bold">
                Ready to <span className="download-text"> Elevate</span> Your
                Team with iWantHub?
              </h2>
              <p className="OpenSans join-text text-color">
                Join thousands of teams already optimizing their work.
              </p>
              <NavLink
                // className="  header-text mx-2 nav-link Feather-font-bold"

                to="/Contact-us"
                // activeClassName="active-link"
              >
                <Button
                  variant="outline-success"
                  className="btn custom-get-started-btn me-2 custom-bottom"
                >
                  Book a Demo
                </Button>
              </NavLink>
            </div>
            <div className="col-md-6 responsive-margin-top">
              <div className="image-container">
                <img className="img-fluid on-hover" src={MobilePerson} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LandingPage;
