import React, { useEffect, useRef } from "react";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import Logo from "../Images/Logo.png";
import { NavLink } from "react-router-dom";
import { Link, animateScroll as scrollAnimate } from "react-scroll";
import { useLocation } from "react-router-dom";
import menue from "../Images/menue.svg";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

function Header() {
  const location = useLocation();
  const Navigate = useNavigate();
  const navbarRef = useRef(null);
  // console.log(location.pathname);
  const [isContactPage, setIsContactPage] = useState(false);
  // const isContactPage = location.pathname === "/contact-us";

  useEffect(() => {
    setIsContactPage(location.pathname === "/Contact-us");
  }, [location.pathname]);
  // console.log(location.pathname);

  // const history = useHistory();

  const handleClick = () => {
    if (location.pathname !== "/") {
      Navigate("/");
      setTimeout(() => {
        scrollAnimate.scrollTo(
          document.getElementById("feature-section").offsetTop,
          {
            smooth: true,
            duration: 100,
            offset: -70,
          }
        );
      }, 500);
    } else {
      scrollAnimate.scrollTo(
        document.getElementById("feature-section").offsetTop,
        {
          smooth: true,
          duration: 100,
          offset: -70,
        }
      );
    }
  };
  const [expanded, setExpanded] = useState(false);

  const handleToggle = () => {
    setExpanded(!expanded);
  };

  const closeNav = () => {
    setExpanded(false);
  };

  return (
    <div
      className={`Feather-font  ${
        isContactPage ? "header-white" : "header-bg"
      }`}
    >
      <div className="custom-container">
        <Navbar
          expand="lg"
          className={`Feather-font  py-4 ${
            isContactPage ? "header-white" : "header-bg "
          }`}
          expanded={expanded}
          onToggle={handleToggle}
        >
          <Navbar.Brand href="#">
            <NavLink to="/">
              <img className="logo-width" src={Logo} alt="" />
            </NavLink>
          </Navbar.Brand>

          <Navbar.Toggle aria-controls="navbarScroll" onClick={handleToggle}>
            <img src={menue} alt="" />
          </Navbar.Toggle>
          <Navbar.Collapse id="navbarScroll">
            <Nav
              className=" w-100 d-flex justify-content-center header-right"
              style={{ maxHeight: "100px" }}
              navbarScroll
            >
              <Link
                className="  header-text mx-2 nav-link Feather-font-bold"
                // to="/#feature-section"
                // onClick={handleClick}

                to="feature-section"
                smooth={true}
                duration={100}
                offset={-70}
                activeClassName="active "
                onClick={() => {
                  handleClick();
                  closeNav();
                }}
              >
                Feature
              </Link>
              <Link
                className="  header-text mx-2 nav-link Feather-font-bold"
                // to="/help-center"
                activeClassName="active-link"
                // onClick={() =>
                //   document
                //     .getElementById("navbarScroll")
                //     .classList.remove("show")
                // }
              >
                Help Center
              </Link>
              {/* <NavDropdown title="Link" id="navbarScrollingDropdown">
                <NavDropdown.Item href="#action3">Action</NavDropdown.Item>
                <NavDropdown.Item href="#action4">
                  Another action
                </NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item href="#action5">
                  Something else here
                </NavDropdown.Item>
              </NavDropdown> */}
              {/* <Nav.Link to="/contactus" className=""> */}
              <NavLink
                // className="  header-text mx-2 nav-link Feather-font-bold"
                className={({ isActive, isPending }) =>
                  `${
                    isPending ? "pending" : isActive ? "active" : ""
                  } header-text mx-2 nav-link Feather-font-bold`
                }
                to="/Contact-us"
                // activeClassName="active-link"
                onClick={closeNav}
              >
                Book a Demo
              </NavLink>
              {/* </Nav.Link> */}
            </Nav>
            {/* <Form className="d-flex">
              <Form.Control
                type="search"
                placeholder="Search"
                className="me-2"
                aria-label="Search"
              /> */}
            <a href="https://dxbtechnologyhub.com/login#" target="_blank">
              <Button
                variant="outline-success"
                className="btn custom-login-btn  Feather-font-bold"
              >
                Login
              </Button>
            </a>
            {/* </Form> */}
          </Navbar.Collapse>
        </Navbar>
      </div>
    </div>
  );
}

export default Header;
