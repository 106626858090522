import React from "react";
import Logo from "../Images/Logo.png";
import LathranLogo from "../Images/lathran-soft-logo.png";
import { NavLink } from "react-router-dom";

function LastFooter() {
  const day = new Date();
  const newDay = day.getDay();
  const today = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  console.log(today[newDay]);
  return (
    //   <div className="LastFooter">
    <div className="custom-container my-3">
      <div className="display-flex">
        <div className="d-flex flex-column flex-md-row ">
          <NavLink to="/">
            <img className="logo-width-footer mb-2 mb-md-0" src={Logo} alt="" />
          </NavLink>
          <p className="OpenSans last-margin ms-md-2  text-md-start">
            is designed, built, and backed by{" "}
            <a
              style={{ textDecoration: "none" }}
              href="https://www.lathran.com/#/"
              target="_blank"
            >
              <span className="Feather-font-bold lathran-color">
                LathranSoft
              </span>
            </a>
            . Enjoy the rest of your
            <span> {today[newDay]}</span>
          </p>
        </div>

        {/* <div className="d-flex">
          <img className="logo-width-footer" src={Logo} alt="" />
          <p className="OpenSans last-margin ms-1">
            is designed, built, and backed by{" "}
            <span className="Feather-font-bold lathran-color">LathranSoft</span>{" "}
            .Enjoy the rest of your Day
          </p>
        </div> */}
        <div>
          <a
            style={{ textDecoration: "none" }}
            href="https://www.lathran.com/#/"
            target="_blank"
          >
            <svg
              width="181"
              height="29"
              viewBox="0 0 181 29"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M147.864 9.22114V11.2746C147.864 11.4794 147.967 11.7824 147.659 11.8891C147.248 11.9923 147.349 11.581 147.248 11.3812C146.427 8.61003 144.065 7.17279 141.292 7.68742C140.896 7.76104 140.515 7.89945 140.163 8.09709C138.521 9.02139 138.521 10.5602 139.958 11.5861C141.499 12.7152 143.344 13.3314 144.99 14.1524C145.809 14.5358 146.598 14.9818 147.349 15.4864C149.609 17.2317 149.609 20.0029 147.349 21.645C145.604 22.979 143.552 23.0822 141.499 22.7741C140.471 22.6725 139.547 22.3644 138.521 22.0563C138.013 21.9531 137.597 21.9531 137.392 22.5642C137.289 22.769 137.186 22.9739 136.981 22.8723C136.673 22.769 136.776 22.5642 136.776 22.3645V18.1509C136.776 17.9461 136.569 17.5347 137.084 17.5347C137.392 17.5347 137.392 17.8428 137.494 18.0426C138.521 21.1219 141.292 22.6624 144.477 21.8414C145.503 21.5333 146.427 21.0186 146.632 19.8912C146.838 18.6588 146.124 17.8378 145.194 17.3248C143.552 16.4005 141.909 15.6828 140.163 14.8617C139.446 14.4504 138.623 14.0407 138.008 13.5278C136.261 11.884 136.261 9.6342 138.11 8.08693C139.855 6.6497 142.003 6.44487 144.169 6.9578C144.676 7.06107 145.091 7.2659 145.604 7.36916C146.324 7.574 147.043 7.87702 147.349 6.86131C147.453 6.65478 147.556 6.65479 147.659 6.65479C147.864 6.65479 147.864 6.8613 147.864 6.96288V9.22114Z"
                fill="#132B3D"
              />
              <path
                d="M88.0925 22.5404H81.8052C81.8633 22.4856 81.8994 22.4116 81.9068 22.3322C83.6606 21.8159 83.6606 21.8159 83.6606 19.8488V9.91172C83.6775 9.52961 83.6428 9.14694 83.5573 8.77412C83.4541 7.7381 83.2475 7.63483 82.2166 7.7381C82.0101 7.7381 81.7087 7.94632 81.5987 7.63484C81.4887 7.32335 81.9068 7.32504 82.1066 7.22178C83.1375 6.9103 84.0652 6.6005 85.0961 6.18576C85.714 5.97923 86.0238 6.08249 86.0238 6.91029V12.6034C86.0238 12.9131 85.9206 13.2246 86.1271 13.6377C86.5401 13.3279 86.8482 13.0181 87.2613 12.7066C87.8706 12.2212 88.5755 11.8699 89.33 11.6757C91.4934 11.1577 93.144 12.2969 93.4521 14.5738C93.6586 16.3327 93.5553 17.9883 93.5553 19.7489C93.5553 21.7143 93.5553 21.7143 95.4175 22.4388H89.1234C89.4332 22.0258 89.7413 22.2323 89.9479 22.129C90.7723 22.0258 91.2869 21.611 91.2869 20.7748C91.2869 18.8094 91.3902 16.739 91.1853 14.7719C91.0821 13.4176 90.3592 12.9098 88.9169 13.013C87.886 13.1163 87.0548 13.6326 86.3404 14.2539C86.1339 14.4621 86.1339 14.6686 86.1339 14.9784V20.3617C86.1339 21.7058 86.3404 21.914 87.7827 22.1206C87.9361 22.2154 88.0471 22.3658 88.0925 22.5404Z"
                fill="#132B3D"
              />
              <path
                d="M42.1523 22.3929C42.6602 21.9815 43.0783 22.188 43.4914 22.0831C44.5206 21.8782 44.9337 21.4652 44.9337 20.4325V8.97702C44.9337 7.73785 44.4174 7.32649 42.9751 7.22323C42.7702 7.22323 42.2539 7.42975 42.2539 6.91343C42.2539 6.60364 42.667 6.70691 42.9751 6.70691H49.8785C50.085 6.70691 50.5997 6.50038 50.5997 6.91343C50.5997 7.32649 50.0918 7.11996 49.8785 7.22323C49.4655 7.32649 49.0541 7.22322 48.6427 7.42975C48.3356 7.52294 48.068 7.71511 47.8815 7.9763C47.695 8.23749 47.6002 8.55304 47.6118 8.87376V20.226C47.6118 21.0521 48.0232 21.4652 48.7443 21.4652C50.5643 21.5867 52.3914 21.5521 54.2055 21.3619C55.6478 21.1554 56.5754 20.3293 57.2966 19.0918C57.3982 18.8853 57.6047 18.5839 57.708 18.369C57.8112 18.2657 57.9145 18.1624 58.1193 18.2657C58.1585 18.3064 58.1881 18.3553 58.206 18.4089C58.2239 18.4625 58.2295 18.5194 58.2226 18.5755C57.7147 19.813 57.2966 21.0521 56.7803 22.2896C56.677 22.4961 56.4722 22.4961 56.2724 22.4961H42.5637C42.5637 22.5994 42.4604 22.4961 42.1523 22.3929Z"
                fill="#132B3D"
              />
              <path
                d="M133.908 22.4559H127.565C127.619 22.3425 127.689 22.2376 127.773 22.1444C129.541 21.8329 129.646 21.7296 129.646 20.0706V15.0937C129.541 13.4347 128.709 12.7084 127.045 13.0199C126.195 13.2462 125.41 13.6728 124.758 14.2641C124.442 14.4707 124.442 14.8922 124.442 15.1969V20.1739C124.442 21.7296 124.648 21.9379 126.209 22.1444C126.312 22.1444 126.416 22.2476 126.521 22.2476C126.001 22.5591 121.217 22.6624 120.282 22.3526V22.2476C121.945 21.8329 122.05 21.7296 122.05 20.0706V14.5756C122.05 13.3314 121.842 13.1232 120.594 13.2214C120.386 13.2214 120.074 13.3263 120.074 13.0148C120.074 12.7033 120.282 12.7033 120.491 12.7033C121.53 12.3919 122.465 12.0821 123.506 11.6673C124.337 11.3558 124.442 11.4591 124.442 12.3919V13.6361C124.857 13.3263 125.168 13.0148 125.481 12.8066C126.416 12.0821 127.457 11.5624 128.698 11.5624C130.56 11.5624 131.914 12.7033 131.914 14.6738C132.017 16.3328 131.914 17.9918 131.914 19.6508C132.036 21.6264 132.036 21.6264 133.908 22.4559Z"
                fill="#132B3D"
              />
              <path
                d="M72.0461 20.6259C71.9675 20.6361 71.8943 20.6718 71.8379 20.7275C71.4231 21.0339 71.0067 21.5451 70.3837 21.2353C69.8657 20.9289 69.969 20.2196 69.969 19.8049V14.4893C69.969 13.1604 69.346 12.4444 68.1069 12.0364C66.9659 11.6284 65.7199 11.6284 64.474 11.8316C63.1197 12.0364 61.9821 12.4444 61.2576 13.6717C60.9461 14.1795 60.9461 14.7957 61.3608 15.2054C61.7756 15.6151 62.2953 15.6134 62.815 15.4086C63.438 15.2054 63.438 14.6942 63.5412 14.1829C63.5412 13.0589 63.956 12.6492 64.9954 12.4444C66.0348 12.2395 67.1741 12.6492 67.4856 13.4601C68.1085 14.789 67.7971 15.5051 66.4479 15.913C66.2413 16.0163 66.0331 16.0163 65.7216 16.1179C64.2675 16.6257 62.815 17.0388 61.5691 18.0596C60.6346 18.8772 60.2148 19.8997 60.6346 20.9222C61.0544 21.9447 61.8805 22.5575 63.0215 22.659C64.0643 22.7648 65.1146 22.551 66.0331 22.0462L67.5905 21.127C68.0053 22.5575 68.525 22.8639 69.666 22.659C70.9034 22.4644 72.1494 21.4436 72.0461 20.6259ZM67.1656 20.5227C66.2329 21.0305 65.3035 21.6467 64.1557 21.2387C62.7016 20.7309 62.495 19.0922 63.636 18.0697C64.6754 17.2521 65.9214 16.9457 67.1656 16.5462C67.4771 16.4429 67.5821 16.5462 67.5821 16.8526V18.9991C67.5821 19.5019 67.9003 20.2163 67.1656 20.5227Z"
                fill="#132B3D"
              />
              <path
                d="M118.112 21.1558C117.604 21.469 117.081 21.364 116.978 20.6344C116.978 20.3229 116.875 20.0098 116.875 19.6966V14.1779C116.875 13.0318 116.255 12.3022 115.224 11.8841C113.883 11.4676 112.644 11.4676 111.304 11.5726C110.169 11.7808 109.137 12.1973 108.517 13.1351C108.301 13.3956 108.174 13.7188 108.156 14.057C108.137 14.3951 108.228 14.7302 108.416 15.0125C108.724 15.5339 109.343 15.4289 109.756 15.3256C110.477 15.1174 110.581 14.596 110.477 13.8664C110.376 13.345 110.581 12.9269 111.097 12.6137C111.82 12.094 113.264 12.094 113.987 12.8236C114.708 13.4483 114.708 14.2829 114.708 15.1174C114.708 15.7421 113.883 15.5339 113.47 15.847C113.264 15.9503 113.057 15.9503 112.748 16.0553C111.304 16.5767 109.756 17.0981 108.515 18.0359C107.588 18.7655 107.381 19.8083 107.691 20.8511C108.001 21.8939 108.825 22.5186 109.961 22.6218C111.097 22.7251 112.128 22.6218 113.056 21.9972L114.603 21.0593C115.016 22.5186 115.326 22.7268 116.465 22.7268C117.704 22.6218 119.045 21.4758 119.148 20.433C118.732 20.7462 118.422 20.9544 118.112 21.1558ZM114.501 20.3229C113.883 20.9476 112.954 21.2608 112.131 21.364C111.309 21.4673 110.582 21.0526 110.171 20.218C109.76 19.3834 109.964 18.6538 110.582 18.1324C111.615 17.1946 113.059 16.8814 114.296 16.36C114.71 16.2567 114.71 16.5699 114.71 16.7781V18.5505C114.605 19.182 115.018 19.9116 114.501 20.3297V20.3229Z"
                fill="#132B3D"
              />
              <path
                d="M161.176 13.6598C159.941 12.2158 158.299 11.7012 156.437 11.7012H155.612C153.141 12.011 151.287 13.3517 150.462 15.7234C149.741 17.9918 150.359 19.9555 152.11 21.4977C154.376 23.5596 158.805 23.3598 160.967 20.9899C162.922 18.8162 163.025 15.825 161.176 13.6598ZM159.734 19.6406C159.529 20.8781 158.808 21.7042 157.674 22.0106C156.44 22.3187 155.409 22.0106 154.483 21.0813C152.937 19.534 152.423 16.3413 153.247 14.276C154.173 12.1108 156.851 11.6978 158.395 13.4499C159.529 14.6874 159.837 16.2346 159.941 17.8852C159.832 18.5064 159.832 19.1243 159.729 19.6406H159.734Z"
                fill="#132B3D"
              />
              <path
                d="M163.451 22.4355C163.51 22.3815 163.546 22.308 163.553 22.2289C165.6 21.8142 165.701 21.6077 165.701 19.5356V13.5277C165.701 12.9047 165.498 12.6982 164.985 12.8015H163.656C163.601 12.8065 163.545 12.7992 163.493 12.7801C163.44 12.7609 163.393 12.7304 163.354 12.6908C163.314 12.6511 163.284 12.6033 163.266 12.5507C163.248 12.4982 163.241 12.4421 163.247 12.3867C163.247 12.0769 163.35 11.9736 163.656 11.9736C165.395 12.3867 165.701 11.4556 165.906 9.9016C166.414 6.17733 171.323 5.13622 173.781 7.00005C174.289 7.4148 174.702 7.82955 174.289 8.55409C173.879 9.17536 173.062 9.17536 172.447 8.65904C172.141 8.34756 171.834 7.93281 171.432 7.62302C170.819 7.00005 170.103 6.79352 169.285 7.105C168.466 7.31153 168.269 8.14103 168.159 8.86558C168.058 9.69507 168.159 10.6261 168.159 11.4556C168.159 11.8704 168.364 11.9736 168.774 11.9736H170.819C171.125 11.9736 171.432 11.9736 171.432 12.3867C171.432 12.7998 171.125 12.8015 170.924 12.8015H168.872C168.462 12.8015 168.259 12.9047 168.259 13.4227V20.4684C168.259 21.6077 168.872 22.1257 169.998 22.2289C170.304 22.2289 170.712 22.1257 171.225 22.5404C168.456 22.4355 166.008 22.4355 163.451 22.4355Z"
                fill="#132B3D"
              />
              <path
                d="M80.6473 20.4499C79.9312 22.18 78.2926 23.0958 76.6539 22.5863C75.5281 22.2816 75.0152 21.5706 75.0152 20.3483V13.7343C75.0152 12.9201 74.8104 12.6154 74.0943 12.8185H73.5864C73.3816 12.8185 73.1768 12.9201 73.1768 12.6154C73.1768 12.4105 73.2783 12.309 73.4832 12.2074C74.1992 11.9027 74.8137 11.3931 75.4282 10.8853C75.9953 10.3349 76.4481 9.67791 76.7605 8.95204C76.8621 8.74721 76.9654 8.44419 77.2684 8.54407C77.5714 8.64395 77.3716 8.85048 77.3716 9.05193V11.2899C77.3716 11.7977 77.5748 11.901 77.9845 11.901H79.828C80.1361 11.901 80.2376 12.0026 80.2376 12.3073C80.2376 12.612 80.0328 12.7153 79.7298 12.7153H77.783C77.375 12.7153 77.1702 12.8168 77.1702 13.2231V19.7355C77.1702 20.3466 77.2718 20.8545 77.8863 21.0593C78.1842 21.1686 78.5077 21.1879 78.8165 21.1147C79.1254 21.0416 79.4059 20.8793 79.6231 20.648C80.1361 20.5515 80.2376 20.1401 80.6473 20.4499Z"
                fill="#132B3D"
              />
              <path
                d="M181 20.4784C180.378 21.8073 179.342 22.625 177.783 22.7265C176.231 22.7265 175.403 21.9089 175.298 20.4784C175.298 18.2303 175.195 16.077 175.298 13.8374C175.298 13.0197 175.092 12.7133 174.264 12.8217H173.643C173.538 12.8217 173.331 12.8217 173.331 12.6185C173.331 12.5153 173.434 12.4137 173.538 12.3104C175.195 11.596 176.334 10.3704 177.059 8.83668C177.162 8.63354 177.265 8.53027 177.473 8.53027C177.682 8.53027 177.68 8.73512 177.68 8.93995V11.3912C177.68 11.8991 177.888 12.004 178.301 12.004H180.163C180.473 12.004 180.671 12.1073 180.671 12.4137C180.671 12.7201 180.361 12.7201 180.163 12.7201H178.301C177.888 12.7201 177.68 12.8217 177.68 13.3329V19.7657C177.68 20.3785 177.783 20.8898 178.405 21.1962C178.706 21.3068 179.033 21.3269 179.346 21.2541C179.659 21.1813 179.943 21.0188 180.165 20.7865C180.475 20.4784 180.587 20.2736 181 20.4784Z"
                fill="#132B3D"
              />
              <path
                d="M103.345 22.5405H96.5317C96.8415 22.0326 97.3578 22.2307 97.6676 22.1274C98.3905 21.9209 98.7003 21.5095 98.7003 20.8899V14.4977C98.7003 13.4668 98.2872 13.2603 97.2546 13.3635C97.0481 13.3635 96.7467 13.5701 96.635 13.157C96.635 12.9522 96.9448 12.8489 97.0481 12.8489C98.0807 12.5391 99.1133 12.1261 100.144 11.818C100.764 11.6114 101.074 11.7147 100.97 12.4358V13.9831C101.59 13.2603 102.003 12.6424 102.623 12.2293C103.449 11.7215 104.273 11.5082 105.099 11.9212C105.616 12.1261 106.029 12.6424 105.822 13.2603C105.616 13.9831 105.099 14.0847 104.376 14.0847C104.158 14.0639 103.946 13.9938 103.759 13.8798C103.242 13.6733 102.726 13.0537 102.106 13.5701C101.383 14.0779 100.764 14.7043 100.764 15.7352V20.0655C100.764 21.6128 101.074 21.9277 102.626 22.2307C103.139 22.2307 103.242 22.2307 103.345 22.5405Z"
                fill="#132B3D"
              />
              <path
                d="M8.99268 28.8604C9.99379 28.3598 10.9241 27.8592 11.8544 27.3587C16.2897 24.8547 20.5091 21.9942 24.0815 18.2742C25.8688 16.4149 27.3717 14.3406 28.0151 11.7658C28.7305 9.05051 28.1579 6.54417 26.1557 4.46992C24.8677 3.18193 23.222 2.75338 21.3626 2.89623C21.1477 2.89623 21.0084 2.96706 20.79 2.96706C20.7515 2.92837 20.7013 2.90347 20.6472 2.89623C22.078 1.32254 23.7237 0.249409 25.8688 0.0345463C28.8013 -0.25115 31.0916 1.25053 32.1636 3.96936C33.6664 7.68814 32.951 11.1932 30.9476 14.4835C29.0162 17.7737 26.2985 20.2777 23.1559 22.3519C18.7878 25.2124 14.0715 27.2158 9.20754 28.9324C9.1794 28.9051 9.14548 28.8844 9.10829 28.8719C9.07111 28.8595 9.0316 28.8555 8.99268 28.8604Z"
                fill="#AB322F"
              />
              <path
                d="M4.95605 26.5231C5.89224 25.9481 6.89926 25.4464 7.76343 24.9435C10.7148 23.1502 13.5222 21.1397 15.7535 18.4846C16.9057 17.0502 17.8419 15.4706 18.0579 13.6053C18.346 10.7342 16.3296 7.79225 13.0181 8.29281C12.9173 8.29597 12.8175 8.27103 12.7301 8.22079C14.0287 6.85724 15.4654 5.99661 17.3343 6.13946C18.987 6.28349 20.0696 7.28815 20.7178 8.79573C21.9408 11.6657 21.3659 14.3208 19.7095 16.905C18.0568 19.5601 15.6779 21.4974 13.0146 23.077C10.6424 24.4276 8.16263 25.5798 5.60064 26.5219C5.31022 26.5231 5.16856 26.6659 4.95605 26.5231Z"
                fill="#89AD3D"
              />
              <path
                d="M10.5212 12.6005C10.0076 11.7942 9.2686 11.1531 8.18248 11.0823C7.09636 11.0115 6.15546 11.4365 5.28656 12.3632C5.93823 12.3632 6.4447 12.3632 6.95234 12.5769C8.39971 13.1459 9.12457 15.0679 8.54492 16.7089C7.96526 18.2034 6.87914 19.3427 5.72101 20.4099C4.77656 21.2646 0.217224 23.8265 0 24.111C0.362433 24.0402 5.06934 21.9765 5.06934 21.9765C7.05977 21.0262 8.4729 19.9117 9.63104 18.1987C10.8588 16.4964 11.5341 14.5968 10.5212 12.6005Z"
                fill="#ED8425"
              />
            </svg>

            {/* <img src={LathranLogo} alt="" /> */}
          </a>
        </div>
      </div>
    </div>

    //   </div>
  );
}

export default LastFooter;
